import s from "./ProductReviews.module.scss";
import {fakeCommentData} from '@data/FakeCommentData.js'
import Comment from "@UI/Comment/Comment.jsx";

const ProductReviews = ({isMaximized}) => {


    return (
        <div className={s.comments}>
            {
                isMaximized ?
                    fakeCommentData.map((comment) => (
                        <div key={comment.id}><Comment comment={comment}> </Comment></div>
                    )) : fakeCommentData.map((comment) => (
                        <div key={comment.id}><Comment comment={comment}> </Comment></div>
                    )).slice(0, 1)
            }
        </div>
    );
};

export default ProductReviews;