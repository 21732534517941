import s from './ShowMoreBtn.module.scss'
import { Link } from 'react-router-dom'

const ShowMoreBtn = ({path}) => {
    return (
        <button className={s.showMoreBtn}>
            <Link
                to={path}
            >
                <span>Ещё</span>
            </Link>

        </button>
    )
}

export default ShowMoreBtn
