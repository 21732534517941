import s from './AuthorsList.module.scss'
import AuthorCard from "@components/AuthorCard/AuthorCard.jsx";
import React from "react";

const AuthorsList = ({ dataList }) => {
	return (
		<>
			{dataList.map((item) => (
				<AuthorCard item={item} key={item.id} />
			))}
		</>
	)
}

export default AuthorsList
