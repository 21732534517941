
export const offeredServicesSectionData = [
	{
		id: 1,
		productPhoto: 'cat4',
		rating: '10.0',
		productName: 'Золотые серьги ручной работы',
		price: '10000',
		productAuthorImg: 'man1',
		productAuthorName: 'Неизвестный',
		productAuthorJob: 'Ювелир',
		productAuthorRating: '10.0',
		tags: ['Ручная работа', 'Золото', 'Серьги', 'Ювелирные украшения'],
	},
	{
		id: 2,
		productPhoto: 'wall',
		rating: '10.0',
		productName: 'Ч/Б Фотосессия',
		price: '2500',
		productAuthorImg: 'woman2',
		productAuthorName: 'Анна Далакян',
		productAuthorJob: 'Фотограф',
		productAuthorRating: '7.0',
		tags: ['Черно-белая фотосессия', 'Фотограф'],
	},
	{
		id: 3,
		productPhoto: 'painting',
		rating: '10.0',
		productName: 'Книга "Вечный путник"',
		price: '5500',
		productAuthorImg: 'man2',
		productAuthorName: 'Артур Дрозд',
		productAuthorJob: 'Писатель',
		productAuthorRating: '0.0',
		tags: ['Черно-белая фотосессия', 'Фотограф'],
	},
	{
		id: 4,
		productPhoto: 'painting2',
		rating: '5.6',
		productName: 'Автопортрет',
		price: '350000',
		productAuthorImg: 'timur',
		productAuthorName: 'Тимур Котик',
		productAuthorJob: 'Котик',
		productAuthorRating: '200.0',
		tags: ['Котик', 'Котик', 'Котик', 'Котик'],
	},
]
