import { offeredServicesSectionData } from './data/offeredServicesSectionData.js'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'

const OfferedServicesSection = () => {
	return (
		<ProductsSectionLayout
			title={'Услуги'}
			data={offeredServicesSectionData}
		></ProductsSectionLayout>
	)
}

export default OfferedServicesSection
