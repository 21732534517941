export const productsSliderItem =[
    {
        id: 1,
        title: 'ТОВАРЫ С ВЫСОКИМ РЕЙТИНГОМ',
        img: 'cubes',
    },
    {
        id: 2,
        title: 'ТОВАРЫ, НАБИРАЮЩИЕ ПОПУЛЯРНОСТЬ',
        img: 'flower',
    },
    {
        id: 3,
        title: 'ТОВАРЫ НОВЫХ АВТОРОВ',
        img: 'puma',
    },
    {
        id: 4,
        title: 'БЫСТРОПРОДАЮЩИЕСЯ ТОВАРЫ',
        img: 'gorshki',
    },
    {
        id: 5,
        title: 'ТОВАРЫ АВТОРОВ, БЫСТРО НАБИРАЮЩИХ ПОПУЛЯРНОСТЬ',
        img: 'braslet',
    }
]