export const anotherProductsAuthorSectionData = [
	{
		id: 1,
		productPhoto: 'artway',
		rating: '8.75',
		productName: 'Картина "Путь наоборот"',
		price: '100 000',
		productAuthorImg: 'author3',
		productAuthorName: 'Леонардо',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Леонардо', 'Искусство', 'Путь наоборот'],
	},
	{   id: 2,
		productPhoto: 'artabstraction',
		rating: '9.0',
		productName: 'Картина "Абстракция"',
		price: '150 000',
		productAuthorImg: 'author3',
		productAuthorName: 'Леонардо',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Портрет', 'На заказ', 'Абстракция', 'Искусство'],
	},
	{
		id: 3,
		productPhoto: 'artgirl',
		rating: '10.0',
		productName: 'Картина "Изаббель"',
		price: '99 999',
		productAuthorImg: 'author3',
		productAuthorName: 'Леонардо',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Искусство', 'На заказ', 'Арт', 'Масляные краски'],
	},
	{
		id: 4,
		productPhoto: 'artgirl2',
		rating: '10.0',
		productName: 'Портреты на заказ',
		price: '50 555',
		productAuthorImg: 'author3',
		productAuthorName: 'Леонардо',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'На заказ', 'Леонардо', 'Живопись'],
	},
]