import s from './Header.module.scss'
import { useEffect, useState } from 'react'
import { GoSearch } from 'react-icons/go'
import { HiPlusSm } from 'react-icons/hi'
import { AiOutlineHeart } from 'react-icons/ai'
import { HeaderContext } from '@context/headerContext/HeaderContext.js'
import DefaultButton from '@UI/DefaultButton'
import FullRoundedButton from '@UI/FullRoundedButton'
import UserMenu from '@UI/UserMenu'
import SearchBar from '@UI/SearchBar'
import Burger from '@UI/Burger'
import DropDownNavMenu from '@components/DropDownNavMenu'
import Logo from '@UI/Logos/Logo'
import LanguageList from '@components/LanguageList/LanguageList.jsx'
import useOutside from '@hooks/useOutside.jsx'
import { observer } from 'mobx-react-lite'
import store from '@store/store.js'

const Header = observer(() => {
  const { isLogin, handleLogin } = store

  const [isOnTop, setIsOnTop] = useState(true)
  const [isSearchBarActive, setIsSearchBarActive] = useState(false)
  const [isDropDownNavMenuActive, setIsDropDownNavMenuActive] =
    useState(false)
  const { ref, isShow, setIsShow } = useOutside(false)
  const handleVisibility = () => {
    setIsShow((prev) => !prev)
  }

  useEffect(() => {
    const topScrollHandler = () => {
      if (window.pageYOffset === 0) {
        setIsOnTop(true)
      }
      if (window.pageYOffset > 1) {
        setIsOnTop(false)
      }
    }

    window.addEventListener('scroll', topScrollHandler)
    return () => removeEventListener('scroll', topScrollHandler)
  }, [window.pageYOffset])

  const setIsSearchBarActiveHandler = () => {
    setIsSearchBarActive((prev) => !prev)

    if (isDropDownNavMenuActive) {
      setIsDropDownNavMenuActiveHandler()
    }
  }
  const setIsDropDownNavMenuActiveHandler = () => {
    setIsDropDownNavMenuActive((prev) => !prev)

    if (isSearchBarActive) {
      setIsSearchBarActiveHandler()
    }
  }
  console.log(isLogin)
  return (
    <HeaderContext.Provider
      value={{ ref, isShow, setIsShow, handleVisibility }}
    >
      <header className={`${s.header} ${isOnTop ? '' : s.active}`}>
        <div className={s.headerWrapper}>
          <div className={s.headerWrapperLeft}>
            <button onClick={handleVisibility}>
              <Burger />
            </button>
            <Logo />
            <DefaultButton>Выставить на продажу</DefaultButton>
            <div className={s.search}>
              <button onClick={setIsSearchBarActiveHandler}>
                <GoSearch />
              </button>
            </div>
          </div>
          {/*<Nav />*/}
          <div className={s.headerWrapperRight}>
            <div className={s.cart}>
              {isLogin && (
                <FullRoundedButton>
                  <HiPlusSm style={{ color: 'white' }} />
                </FullRoundedButton>
              )}
              {isLogin && (
                <button
                  style={{ color: 'white' }}
                  className={s.cartBalance}
                >
                  $300
                </button>
              )}
              <FullRoundedButton wideCart>
                <img src={`icons/cart.svg`} alt='cart' style={{ color: 'white', width: 23 }}/>
              </FullRoundedButton>
            </div>
            <FullRoundedButton mini userMenu>
              <AiOutlineHeart style={{ color: 'white' }} />
            </FullRoundedButton>

            <UserMenu />
            <LanguageList />
          </div>
        </div>
        <DropDownNavMenu
          setIsDropDownNavMenuActiveHandler={
            setIsDropDownNavMenuActiveHandler
          }
          isOnTop={isOnTop}
        />
        <SearchBar
          isSearchBarActive={isSearchBarActive}
          isOnTop={isOnTop}
        />
      </header>
    </HeaderContext.Provider>
  )
})

export default Header
