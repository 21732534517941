export const mainSectionsData = [
	{
		id: 1,
		img: 'popular-1',
		title: 'Изобразительное искусство',
	},
	{
		id: 2,
		img: 'popular-2',
		title: 'Ювелирные украшения',
	},
	{
		id: 3,
		img: 'popular-3',
		title: 'Скульптуры',
	},
	{
		id: 4,
		img: 'popular-3',
		title: 'Скульптуры',
	},
	{
		id: 5,
		img: 'popular-3',
		title: 'Скульптуры',
	},
	{
		id: 6,
		img: 'popular-3',
		title: 'Скульптуры',
	},
]
