import s from './AddToCartBtn.module.scss'
import {useState} from "react";

const AddToCartBtn = ({isBig, product}) => {
    const [isClicked, setIsClicked] = useState(false)
    const setClicked = () => {
        if (product || isBig) {
            setIsClicked(prev => !prev)
        }
    }
    return (
        <button onClick={() => {
            setClicked()
        }} className={`${s.addToCartBtn} ${isBig ? s.isBig : ''} ${isClicked ? s.isClicked : ''}`}>
            {isClicked ? <span>Добавлено!</span> : <div><img src={`icons/cart.svg`} alt='cart'/><span>В корзину</span></div>}
        </button>
    )
}

export default AddToCartBtn
