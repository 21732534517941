export const dropDownNavMenuData = [
  {
    id: 1,
    name: 'Товары',
    tabs: [
      {
        id: 1,
        name: 'Картины',
        products: [
          {
            id: 1,
            name: 'Картина',
            img: 'menu-image1',
            link: '/abstractions',
          },
          {
            id: 2,
            name: 'Портрет',
            img: 'menu-image2',
            link: '/abstractions',
          },
          {
            id: 3,
            name: 'Пейзаж',
            img: 'menu-image3',
            link: '/abstractions',
          },
          {
            id: 4,
            name: 'Портрет',
            img: 'menu-image4',
            link: '/portrait',
          },
          {
            id: 5,
            name: 'Горы',
            img: 'menu-image5',
            link: '/landscape',
          },
          {
            id: 6,
            name: 'Озеро',
            img: 'menu-image6',
            link: '/abstractions',
          },
          {
            id: 7,
            name: 'Рыбак',
            img: 'menu-image7',
            link: '/abstractions',
          },
          {
            id: 8,
            name: 'Рассвет',
            img: 'menu-image8',
            link: '/abstractions',
          },
          {
            id: 9,
            name: 'Полнолуние',
            img: 'menu-image9',
            link: '/abstractions',
          },
          {
            id: 10,
            name: 'Закат',
            img: 'menu-image10',
            link: '/abstractions',
          },
          {
            id: 11,
            name: 'Шар',
            img: 'menu-image11',
            link: '/abstractions',
          },
          {
            id: 12,
            name: 'Дирижабль',
            img: 'menu-image12',
            link: '/abstractions',
          },
        ],
      },
      {
        id: 2,
        name: 'Фотографии',
        products: [
          {
            id: 1,
            name: 'Ночная Москва',
            img: 'foto-image1',
          },
          {
            id: 2,
            name: 'Жемчущина',
            img: 'foto-image2',
          },
          {
            id: 3,
            name: 'Радужное небо',
            img: 'foto-image3',
          },
          {
            id: 4,
            name: 'Кошка',
            img: 'foto-image4',
          },
        ],
      },
      {
        id: 3,
        name: 'Скульптуры',
        products: [
          {
            id: 1,
            name: 'Мужчина',
            img: 'sculp-image1',
          },
          {
            id: 2,
            name: 'Три девушки',
            img: 'sculp-image2',
          },
          {
            id: 3,
            name: 'Скульптура',
            img: 'sculp-image3',
          },
          {
            id: 4,
            name: 'Кошки',
            img: 'sculp-image4',
          },
        ],
      },
      {
        id: 4,
        name: 'Ручные изделия',
        products: [
          {
            id: 1,
            name: 'Украшения',
            img: 'hand-image1',
          },
          {
            id: 2,
            name: 'Сувениры',
            img: 'hand-image2',
          },
          {
            id: 3,
            name: 'Художественные материалы',
            img: 'hand-image3',
          },
          {
            id: 4,
            name: 'Сумки',
            img: 'hand-image4',
          },
        ],
      },
      {
        id: 5,
        name: 'Цифровое исскуство',
        products: [
          {
            id: 1,
            name: 'Моушн дизайн',
            img: 'digital-image1',
          },
          {
            id: 2,
            name: '3D',
            img: 'digital-image2',
          },
          {
            id: 3,
            name: '2D',
            img: 'digital-image3',
          },
          {
            id: 4,
            name: 'Анимация',
            img: 'digital-image4',
          },
        ],
      },
      {
        id: 6,
        name: 'Музыка',
        products: [
          {
            id: 1,
            name: 'Барабаны',
            img: 'music-image1',
          },
          {
            id: 2,
            name: 'Гитара',
            img: 'music-image2',
          },
          {
            id: 3,
            name: 'Микрофон',
            img: 'music-image3',
          },
          {
            id: 4,
            name: 'Синтезатор',
            img: 'music-image4',
          },
        ],
      },
      {
        id: 7,
        name: 'Книги,сценарии',
        products: [
          {
            id: 1,
            name: 'Черная книга',
            img: 'books-image1',
          },
          {
            id: 2,
            name: 'Голубая книга',
            img: 'books-image2',
          },
          {
            id: 3,
            name: 'Мотор',
            img: 'books-image3',
          },
          {
            id: 4,
            name: 'Сценарий',
            img: 'books-image4',
          },
        ],
      },
      {
        id: 8,
        name: 'Выставки',
        products: [
          {
            id: 1,
            name: 'Онлайн ',
            img: 'show-image1',
          },
          {
            id: 2,
            name: 'Оффлайн',
            img: 'show-image2',
          },
        ],
      },
      {
        id: 9,
        name: 'Прочее',
        products: [
          {
            id: 1,
            name: 'Мастер-классы',
            img: 'other-image1',
          },
          {
            id: 2,
            name: 'Гайды',
            img: 'other-image2',
          },
          {
            id: 3,
            name: 'Уроки',
            img: 'other-image3',
          },
          {
            id: 4,
            name: 'Подарки',
            img: 'other-image4',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Услуги',
    tabs: [
      {
        id: 1,
        name: 'Роспись стен',
        products: [
          {
            id: 1,
            name: 'Стена 1',
            img: 'books-image4',
          },
          {
            id: 2,
            name: 'Стена 2',
            img: 'books-image4',
          },
          {
            id: 3,
            name: 'Стена 3',
            img: 'books-image4',
          },
          {
            id: 4,
            name: 'Стена 4',
            img: 'books-image4',
          },
        ],
      },
      {
        id: 2,
        name: 'Картины на заказ',
        products: [
          {
            id: 1,
            name: 'Кошка',
            img: 'foto-image4',
          },
          {
            id: 2,
            name: 'Картина',
            img: 'other-image2',
          },
          {
            id: 3,
            name: 'Фотоо 3',
            img: 'books-image4',
          },
          {
            id: 4,
            name: 'Мечты',
            img: 'digital-image4',
          },
        ],
      },
      {
        id: 3,
        name: 'Музыка на заказ',
        products: [
          {
            id: 1,
            name: 'Барабаны',
            img: 'music-image1',
          },
          {
            id: 2,
            name: 'Гитара',
            img: 'music-image2',
          },
          {
            id: 3,
            name: 'Микрофон',
            img: 'music-image3',
          },
          {
            id: 4,
            name: 'Синтезатор',
            img: 'music-image4',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Творцы',
    tabs: [
      {
        id: 1,
        name: 'Художники',
        products: [],
      },
      {
        id: 2,
        name: 'Фотографы',
        products: [],
      },
      {
        id: 3,
        name: 'Скульпторы',
        products: [],
      },
      {
        id: 4,
        name: 'Музыканты',
        products: [],
      },
      {
        id: 5,
        name: 'Писатели,сценаристы',
        products: [],
      },
    ],
  },
]
