import AuthorsSectionLayout from "../../layouts/AuthorsSectionLayout/AuthorsSectionLayout.jsx";
import {authorsSectionData} from "./data/authorsSectionData.js";
const AuthorsSection = () => {
	return (
		<>
			<AuthorsSectionLayout data={authorsSectionData} title='Топ авторов'/>
		</>


	)
}
export default AuthorsSection
