import s from './FullRoundedButton.module.scss'
import { useState } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { observer } from 'mobx-react-lite'
import store from '@store/store.js'

const FullRoundedButton = observer(
  ({ children, product, userMenu, blackWhite, wideCart, mini }) => {
    const [isClicked, setIsClicked] = useState(false)
    const { isLogin } = store

    const onClick = () => {
      if (product) {
        setIsClicked(!isClicked)
      }
    }

    return (
      <button
        style={
          !isLogin && wideCart ? { width: '60px' } : { width: 'unset' }
        }
        onClick={onClick}
        className={`${s.fullRoundedButton} ${product ? s.product : ''} ${
          userMenu ? s.userMenu : ''
        } ${isClicked ? s.isClicked : ''} ${
          blackWhite ? s.blackWhite : ''
        }  ${mini ? s.mini : ''}`}
      >
        {isClicked ? <AiOutlineCheck /> : children}
      </button>
    )
  },
)

export default FullRoundedButton
