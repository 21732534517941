import s from './MainSections.module.scss'
import "@components/MainSections/MainSection.css"
import cn from 'classnames'
import PictureStyledProductCard from '@components/PictureStyledProductCard'
import {mainSectionsData} from './data/mainSectionsData.js'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import React, { useRef, useState } from 'react';

const MainSections = () => {
    const swiperStyles = cn('mySwiper', 'mySwiper4', s.swiper)

    return (
        <div className={s.mainSectionsWrapper}>
            <h2>Основные разделы</h2>
            <Swiper
                className={swiperStyles}
                modules={[Navigation]}
                navigation={true}
                loop={true}
                spaceBetween={15}
                slidesPerView={3}
            >
                {mainSectionsData.map(({title, img, id}) => (
                    <SwiperSlide key={id}>
                        <PictureStyledProductCard title={title} img={img} />
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    )
}

export default MainSections
