import {fakeAuthorData} from "@data/FakeAuthorData.js";

export const fakeCommentData = [
    {
        id: 1,
        commentRating: 10.450,
        commentText: 'Картина наполнена такой эмоциональной глубиной и яркими цветами, которые создают чувство гармонии и спокойствия. Каждый раз, когда я смотрю на нее, я замечаю новые детали и тонкие нюансы, которые раньше ускользали от моего взгляда.',
        commentAttachments: [{
            id: 1,
            type: 'img',
            src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
        }, {
            id: 2,
            type: 'img',
            src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
        }],
        commentDate: '10.01.23',
        author_name: 'Автор1',
    },
    {
        id: 2,
        commentRating: 10.65,
        commentText: 'Господи, я сижу в подвале, привязанный к батарее и меня заставляют писать комменты, помогите.',
        commentAttachments: [
            {
                id: 1,
                type: 'img',
                src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
            },
            {
                id: 2,
                type: 'img',
                src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
            },
            {
                id: 3,
                type: 'img',
                src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
            }],
        commentDate: '16.10.96',
        author_name: 'Автор2',

    },
    {
        id: 3,
        commentRating: 6.45,
        commentText: 'Зачетные коты.',
        commentAttachments: [{
            id: 1,
            type: 'img',
            src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
        }, {
            id: 2,
            type: 'img',
            src: 'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'
        }],
        commentDate: '16.10.96',
        author_name: 'Anonimus374',

    }
]