import { useState } from 'react'
import { useForm } from 'react-hook-form'
import s from '@pages/LoginPage/LoginForm/LoginForm.module.scss'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BsGoogle } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'

const RegistrationForm = () => {
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShown2, setPasswordShown2] = useState(false)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    alert(JSON.stringify(data))
    reset()
  }
  const togglePassword = () => {
    setPasswordShown((p) => !p)
  }
  const togglePassword2 = () => {
    setPasswordShown2((p) => !p)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className={s.input}>
        Email / номер телефона
        <input
          {...register('emailNumber', {
            required: 'Поле обязательно к заполнению',
            minLength: {
              value: 5,
              message: 'Минимум 5 символов',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Некорректный Email',
            },
          })}
          className={s.number}
          type="tel"
          placeholder="Введите Email/номер телефона"
        />
        {(errors?.emailNumber && (
          <span className={s.errorSpan}>
            {errors?.emailNumber?.message}
          </span>
        )) ||
          ''}
      </label>
      <label className={s.input}>
        Пароль
        <div className={s.inputWrapper}>
          <input
            {...register('password', {
              required: 'Поле обязательно к заполнению',
              minLength: {
                value: 7,
                message: 'Минимум 7 символов',
              },
            })}
            className={s.number}
            placeholder="Введите пароль"
            type={passwordShown ? 'text' : 'password'}
          />

          <button
            type="button"
            className={s.showPass}
            onClick={togglePassword}
          >
            {passwordShown ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>
        </div>
        {(errors?.password && (
          <span className={s.errorSpan}>{errors?.password?.message}</span>
        )) ||
          ''}
      </label>

      <label className={s.input}>
        Повторите пароль
        <div className={s.inputWrapper}>
          <input
            {...register('repeatPassword', {
              required: 'Поле обязательно к заполнению',
              minLength: {
                value: 7,
                message: 'Минимум 7 символов',
              },
            })}
            className={s.number}
            placeholder="Повторите пароль"
            type={passwordShown2 ? 'text' : 'password'}
          />

          <button
            type="button"
            className={s.showPass}
            onClick={togglePassword2}
          >
            {passwordShown2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>
        </div>
        {(errors?.repeatPassword && (
          <span className={s.errorSpan}>
            {errors?.repeatPassword?.message}
          </span>
        )) ||
          ''}
        <label className={s.checkbox}>
          <div className={s.box}>
            <input
              type="checkbox"
              className={s.checkboxInput}
              id="checkbox1"
            />
            <label
              className={s.checkboxLabel}
              style={{ fontSize: '14px' }}
              for="checkbox1"
            >
              Принять правила площадки и политики конфиденциальности
            </label>
          </div>
        </label>
      </label>

      <div className={s.bottomWrapper}>
        <button className={s.submitBtn} disabled={!isValid} type="submit">
          Войти
        </button>

        <div className={s.resetPass}>Забыли пароль?</div>

        <ul className={s.loginWith}>
          <li>
            <BsGoogle className={s.google} />
          </li>
          <li>
            <img
              className={s.vk}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/VK_logo.svg/768px-VK_logo.svg.png?20200611104337"
              alt=""
            />
          </li>
          <li>
            <FaTelegramPlane className={s.telegram} />
          </li>
        </ul>
      </div>
    </form>
  )
}

export default RegistrationForm
