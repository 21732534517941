import { anotherProductsAuthorSectionData } from './data/anotherProductsAuthorSectionData.js'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'

const AnotherProductsAuthorSection = () => {
	return (
		<ProductsSectionLayout
			title={'Другие товары этого творца'}
			data={anotherProductsAuthorSectionData}
		></ProductsSectionLayout>
	)
}
 
export default AnotherProductsAuthorSection