import s from './PopularCategoriesSection.module.scss'
import "@components/PopularCategoriesSection/PopularCategoriesSection.css"
import cn from 'classnames'
import PictureStyledProductCard from '@components/PictureStyledProductCard'
import { popularCategoriesSection } from './data/popularCategoriesSection.js'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import React, { useRef, useState } from 'react';

const PopularCategoriesSection = () => {
	const swiperStyles = cn('mySwiper', 'mySwiper3', s.swiper)
	
	return (
		<div className={s.popularCategories}>
			<h2>Популярные категории</h2>
			<Swiper
			className={swiperStyles}
			modules={[Navigation]}
			navigation={true}
			loop={true}
			spaceBetween={15}
			slidesPerView={3}
		>
			{popularCategoriesSection.map(({title, img, id}) => (
				<SwiperSlide key={id}>
					<PictureStyledProductCard title={title} img={img} />
				</SwiperSlide>
			))}

		</Swiper>
		</div>
	)
}

export default PopularCategoriesSection
