import {fakeCommentData2} from "@data/FakeCommentData2.js";
import Comment from "@UI/Comment/Comment.jsx";
import React from "react";
import s from './ProductExpertReviews.module.scss'

const ProductExpertReviews = ({isMaximized}) => {
    return (
        <div className={s.comments}>
            {
                isMaximized ?
                    fakeCommentData2.map((comment) => (
                        <div key={comment.id}><Comment comment={comment}> </Comment></div>
                    )) : fakeCommentData2.map((comment) => (
                        <div key={comment.id}><Comment comment={comment}> </Comment></div>
                    )).slice(0, 1)
            }
        </div>
    );
};

export default ProductExpertReviews;