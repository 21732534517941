import { offersSectionData } from './data/offersSectionData'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'
import { observer } from 'mobx-react-lite'
import store from '@store/store.js'
import {
  IS_LOGIN_DATA,
  IS_NOT_LOGIN_DATA,
} from '../../constants/isLogin/data.js'

const OffersSection = observer(() => {
  const { isLogin } = store

  const title = IS_LOGIN_DATA.offersSection.title
  const unLoginTitle = IS_NOT_LOGIN_DATA.OffersSection.title

  return (
    <ProductsSectionLayout
      title={isLogin ? 'Предложения для вас' : 'Услуги'}
      data={offersSectionData}
    ></ProductsSectionLayout>
  )
})

export default OffersSection
