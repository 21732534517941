export const authorsSectionData = [
    {
        id: 1,
        authorPhoto: 'man1',
        rating: '10.0',
        name: 'Автор',
        surname: 'Автор',
        profession: 'Красавчик',
    },
    {
        id: 2,
        authorPhoto: 'woman1',
        rating: '10.0',
        name: 'Элина',
        surname: 'Аксионова',
        profession: 'Фотограф',
    },
    {
        id: 3,
        authorPhoto: 'woman2',
        rating: '10.0',
        name: 'Алекса',
        surname: 'Мэтью',
        profession: 'Художник',
    },
    {
        id: 4,
        authorPhoto: 'man2',
        rating: '10.0',
        name: 'Иван',
        surname: 'Самко',
        profession: 'Digital художник',
    },
]
