import s from './HoverSearchLink.module.scss'
import cn from 'classnames'
import { GoSearch } from 'react-icons/go'

const HoverSearchLink = ({ small, mdRounded, maxRounded, height320 }) => {
	const classNames = cn(s.hoverSearchLink, {
		[s.small]: small,
		[s.mdRounded]: mdRounded,
		[s.maxRounded]: maxRounded,
		[s.height320]: height320,
	})

	return (
		<span className={classNames}>
			<GoSearch />
		</span>
	)
}

export default HoverSearchLink
