import AuthorsSection from '@components/AuthorsSection/index.jsx'
import EventsSection from '@components/EventsSection/index.jsx'
import MainSections from '@components/MainSections/index.jsx'
import OfferedProductsSection from '@components/OfferedProductsSection/index.jsx'
import OfferedServicesSection from '@components/OfferedServicesSection/index.jsx'
import OffersSection from '@components/OffersSection/index.jsx'
import PopularCategoriesSection from '@components/PopularCategoriesSection/index.jsx'
import ProductsSliderSection from '@components/ProductsSliderSection/index.jsx'
import SubscribedOffersSection from '@components/SubscribedOffersSection/index.jsx'
import s from './HomePage.module.scss'
import RecommendedAuthorsSection from '@components/RecommendedAuthorsSection/index.jsx'
import { useEffect } from 'react'
import { scrollToTop } from '@hooks/scrollToTop.js'
import { observer } from 'mobx-react-lite'
import store from '@store/store.js'

const HomePage = observer(() => {
  const { isLogin } = store
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <EventsSection />
      <SubscribedOffersSection />
      <ProductsSliderSection />
      <OffersSection />
      <PopularCategoriesSection />
      <AuthorsSection />
      <MainSections />
      {isLogin && <OfferedProductsSection />}
      <RecommendedAuthorsSection />
      {isLogin && <OfferedServicesSection />}
    </>
  )
})

export default HomePage
