import s from './BigProductCard.module.scss'
import AddToCartBtn from "@UI/AddToCartBtn/index.jsx";
import FullRoundedButton from "@UI/FullRoundedButton/index.jsx";
import {BsSuitHeartFill} from "react-icons/bs";
import {BsShare} from 'react-icons/bs'
import {BsChatSquareDots} from 'react-icons/bs'
import {BsTelephone} from 'react-icons/bs'
import ImageGalleryLayout from "@components/ImageGallery/ImageGalleryLayout.jsx";
import {BsCameraVideo} from 'react-icons/bs'
import {useContext} from "react";
import {ProductCardContext} from "@context/ProductCardContext/ProductCardContext.js";
import {BsBell} from 'react-icons/bs'

const BigProductCard = () => {

    const context = useContext(ProductCardContext)

    const {
        productName,
        rating,
        price,
        description,
        tags
    } = context

    return (
        <div className={s.cardWrapper}>
            <ImageGalleryLayout/>
            <div className={s.itemInfo}>
                <div className={s.itemInfoHeadingWrapper}>
                    <h2 className={s.itemInfoTitle}>{productName}</h2>
                    <button className={s.subscribeNotifications}><BsBell/></button>
                </div>
                <span className={s.itemInfoRating}>{rating}</span>
                <span className={s.itemInfoPrice}>{price} ₽</span>
                <p className={s.itemInfoDescription}><span>Описание: </span>{description}</p>
                <ul className={s.itemInfoTags}>
                    {tags.map((item, i) => (
                    <li key={i}>
                        <a href='#'>#{item}</a>
                    </li>
                ))}
                </ul>
                <div className={s.itemInfoButtons}>
                    <div className={s.itemInfoButtonsLeft}>
                        <AddToCartBtn isBig={true}/>
                        <FullRoundedButton product={'product'}>
                            <BsSuitHeartFill/>
                        </FullRoundedButton>
                        <BsShare className={s.share}/>
                    </div>
                    <div className={s.itemInfoButtonsRight}>
                        <BsChatSquareDots/>
                        <BsTelephone/>
                    </div>
                </div>
                <div className={s.itemInfoOrderButtons}>
                    <div className={s.itemInfoOrderButtonsWrapper}>
                        <button className={s.greyButton}>
                            <BsChatSquareDots/><p>Заказать консультацию</p>
                        </button>
                        <button className={s.greyButton}>
                            <BsCameraVideo/><p>Прямой эфир с творцом</p>
                        </button>
                    </div>
                    <div className={s.additionalButtons}>
                        <a href="">Открыть спор</a>
                        <a href="">Оставить отзыв</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BigProductCard