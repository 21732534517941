import s from './ProductsSliderChildItem.module.scss'
import {Link} from 'react-router-dom'

const ProductsSliderChildItem = ({title, img}) => {
    return (
        <div className={s.childItem}>
            <Link>
                <span className={s.heading}>
                    <h2>{title}</h2>
                </span>
                <img src={`./images/products/${img}.jpg`} alt=""/>

            </Link>
        </div>
    )
}
export default ProductsSliderChildItem