import s from './Burger.module.scss'

function Burger() {
    return (
        <div className={s.burger}>
            <span className={s.burgerBody}></span>
        </div>
    )
}

export default Burger
