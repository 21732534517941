import {HiLanguage} from 'react-icons/hi2'
import {AiOutlineCloseCircle} from 'react-icons/ai'
import {useState} from "react";
import s from './LanguageList.module.scss'
import {languageListData} from "@data/languageListData.js";
import {BsCheck2} from 'react-icons/bs'
import useOutside from "@hooks/useOutside.jsx";

const LanguageList = () => {
    const [currentLanguage, setCurrentLanguage] = useState('Ru')
    const {ref, isShow, setIsShow} = useOutside(false)
    const [isTabActive, setIsTabActive] = useState(1)

    const handleVisibility = () => {
        setIsShow(prev => !prev)
    }
    const handleIsTabActive = (index) => {
        setIsTabActive(index)
    }

    return (
        <div className={s.languageList}>
            <div onClick={handleVisibility}
                 className={`${s.languageListWrapper} ${isShow ? s.isActive : ''}`}>
                <HiLanguage/>
                {currentLanguage}
            </div>

            {
                isShow ?
                    <div ref={ref} className={s.languageListDrop}>
                        <AiOutlineCloseCircle onClick={handleVisibility} className={s.closeBtn}/>

                        <span><HiLanguage/></span>
                        <ul>
                            {languageListData.map(({id, name, code}) => (
                                <li onClick={() => {
                                    setCurrentLanguage(code)
                                    handleIsTabActive(id)
                                }} key={id}>
                                    {isTabActive === id ? <BsCheck2 className={s.check}/> : null}
                                    {name}
                                </li>
                            ))}
                        </ul>
                    </div>
                    : null
            }

        </div>
    );
};

export default LanguageList;