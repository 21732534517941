import React from 'react'
import s from './AuthorsSectionLayout.module.scss'
import ShowMoreBtn from "@UI/ShowMoreBtn/index.jsx";
import {authorsSectionData} from "@components/AuthorsSection/data/authorsSectionData.js";
import AuthorsList from "../AuthorsList/index.jsx";

const AuthorsSectionLayout = ({ title, data }) => {
    return (
        <section className={s.authorsSection}>
            <div className={s.contentWrapper}>
                <h2>{title} <ShowMoreBtn/> </h2>
                <div className={s.wrapper}>
                    <AuthorsList dataList={data}/>
                </div>
            </div>
        </section>
    )
}

export default AuthorsSectionLayout
