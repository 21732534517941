import { subscribedOffersSectionData } from './data/subscribedOffersSectionData.js'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'
import {observer} from "mobx-react-lite";
import store from "@store/store.js";
import {IS_LOGIN_DATA, IS_NOT_LOGIN_DATA} from "../../constants/isLogin/data.js";

const SubscribedOffersSection = observer(() => {
	const {isLogin} = store

	const title = IS_LOGIN_DATA.subscribedOffersSection.title
	const unLoginTitle = IS_NOT_LOGIN_DATA.subscribedOffersSection.title

	return (
		<ProductsSectionLayout
			title={isLogin ? title : unLoginTitle}
			data={subscribedOffersSectionData}
		></ProductsSectionLayout>
	)
})

export default SubscribedOffersSection
