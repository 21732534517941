import s from './Comment.module.scss'
import UserTabInfo from "@components/ProductCardTabs/UserTabInfo/UserTabInfo.jsx";
import {fakeAuthorData} from "@data/FakeAuthorData.js";
import {AiOutlineDislike, AiOutlineLike} from 'react-icons/ai'
import {useMemo, useState} from "react";
const Comment = ({comment}) => {
    const [likeCount, setLikeCount] = useState(50);
    const [dislikeCount, setDislikeCount] = useState(25);
    const [activeBtn, setActiveBtn] = useState("none");

    const handleLikeClick = () => {
        if (activeBtn === "none") {
            setLikeCount(likeCount + 1);
            setActiveBtn("like");
            return;
        }

        if (activeBtn === 'like') {
            setLikeCount(likeCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn === "dislike") {
            setLikeCount(likeCount + 1);
            setDislikeCount(dislikeCount - 1);
            setActiveBtn("like");
        }
    }
    const handleDislikeClick = () => {
        if (activeBtn === "none") {
            setDislikeCount(dislikeCount + 1);
            setActiveBtn("dislike");
            return;
        }

        if (activeBtn === 'dislike') {
            setDislikeCount(dislikeCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn === "like") {
            setDislikeCount(dislikeCount + 1);
            setLikeCount(likeCount - 1);
            setActiveBtn("dislike");
        }
    }

    const handleActive = (index) => {
        setActiveBtn(index)
    }


    const author = useMemo(() => {
        return fakeAuthorData.find((el) => el.authorName === comment.author_name)
    }, [comment, fakeAuthorData])

    const {
        id,
        commentRating,
        commentText,
        commentAttachments,
        commentDate,
        authorInfo
    } = comment

    const {
        authorImg,
        authorName,
        authorJob,
        authorRating,
        authorDescription
    } = author

    const {
        src,
        type
    } = commentAttachments
    return (
        <div className={s.comment}>

            <UserTabInfo authorImg={authorImg} authorName={authorName}
                         authorJob={authorJob} authorRating={authorRating}/>

            <div className={s.commentBody}>
                <div className={s.commentBodyTop}>
                    <div className={s.commentBodyTopRating}>
                        {commentRating.toFixed(1)}
                    </div>
                    {commentDate}
                </div>
                <p className={s.commentBodyText}>
                    <span>Отзыв:</span>
                    {commentText}
                </p>
                <ul className={s.commentBodyAttachments}>
                    {commentAttachments.map((item, index) => (
                            <li key={index}>
                                {item.type === 'img' ? <img src={`${item.src}`} alt=""/> : item.type === 'video' ? <video src={item.src}></video> : null}
                            </li>
                        )
                    )}
                </ul>
                <div className={s.commentBodyLikes}>
                    <div className={s.count}>
                        <AiOutlineLike onClick={
                            () => {
                                handleActive('like')
                                handleLikeClick()
                            }} className={`${s.commentBodyLikesLike} ${activeBtn === 'like' ? s.active : ''}`}/>
                        <span className={s.countLikes}>{likeCount}</span>
                    </div>
                    <div className={s.count}>
                        <AiOutlineDislike onClick={
                            () => {
                                handleActive('dislike')
                                handleDislikeClick()
                            }} className={`${s.commentBodyDislikesLike} ${activeBtn === 'dislike' ? s.active : ''}`}/>
                        <span className={s.countDislikes}>{dislikeCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comment;