export const subscribedOffersSectionData = [
	{
		id: 1,
		productPhoto: 'jewerly',
		rating: '10.0',
		productName: 'Золотые серьги ручной работы',
		price: '10000',
		productAuthorImg: 'man1',
		productAuthorName: 'Неизвестный',
		productAuthorJob: 'Ювелир',
		productAuthorRating: '10.0',
		tags: ['Ручная работа', 'Золото', 'Серьги', 'Ювелирные украшения'],
	},
	{
		id: 2,
		productPhoto: 'girl',
		rating: '10.0',
		productName: 'Ч/Б Фотосессия',
		price: '2500',
		productAuthorImg: 'woman2',
		productAuthorName: 'Анна Далакян',
		productAuthorJob: 'Фотограф',
		productAuthorRating: '7.2',
		tags: ['Черно-белая фотосессия', 'Фотограф'],
	},
	{
		id: 3,
		productPhoto: 'cat2',
		rating: '10.0',
		productName: 'Книга "Вечный путник"',
		price: '5500',
		productAuthorImg: 'man2',
		productAuthorName: 'Артур Дрозд',
		productAuthorJob: 'Писатель',
		productAuthorRating: '6.0',
		tags: ['Черно-белая фотосессия', 'Фотограф'],
	},
	{
		id: 4,
		productPhoto: 'sculp',
		rating: '10.0',
		productName: 'Скульптура "Голова Эйнштейна"',
		price: '3500',
		productAuthorImg: 'woman1',
		productAuthorName: 'Анастасия Кравец',
		productAuthorJob: 'Скульптор',
		productAuthorRating: '6.3',
		tags: ['Ручная работа', 'Скульптуры', 'Эйнштейн', 'Креатив'],
	},
]
