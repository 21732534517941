import React, {useState} from "react";
import s from './ProductCardTabs.module.scss'
import AuthorInfo from "@components/ProductCardTabs/AuthorInfo";
import ProductReviews from "@components/ProductCardTabs/ProductReviews";
import ProductExpertReviews from "@components/ProductCardTabs/ProductExpertReviews";
import {FiMaximize2} from "react-icons/fi";
import {BiCollapseAlt} from 'react-icons/bi'

const ProductCardTabs = () => {
    const [currentTab, setCurrentTab] = useState('authorInfo')
    const [isTabActive, setIsTabActive] = useState('authorInfo')
    const [isMaximized, setIsMaximized] = useState(false)

    const handleTabClick = (tab) => {
        setCurrentTab(tab)
        setIsTabActive(tab)
    }
    const handleMaximized = () => {
        setIsMaximized(prev => !prev)
    }
    const tabs = {
        authorInfo: <AuthorInfo/>,
        productReviews: <ProductReviews isMaximized = {isMaximized}/>,
        productExpertReviews: <ProductExpertReviews isMaximized = {isMaximized}/>
    }

    return (
        <div className={s.productCardTabs}>
            <button onClick={handleMaximized} className={s.maximize}>
                {isMaximized ? <BiCollapseAlt/> : <FiMaximize2/>}
            </button>
            <ul className={s.productCardTabsList}>
                <button className={isTabActive === 'authorInfo' ? s.productCardTabsListActive : ''}
                        onClick={() => handleTabClick('authorInfo')}>О творце
                </button>
                <button className={isTabActive === 'productReviews' ? s.productCardTabsListActive : ''}
                        onClick={() => handleTabClick('productReviews')}>Отзывы
                </button>
                <button className={isTabActive === 'productExpertReviews' ? s.productCardTabsListActive : ''}
                        onClick={() => handleTabClick('productExpertReviews')}>Отзывы от экспертов
                </button>
            </ul>
            {tabs[currentTab]}
        </div>
    );
};

export default ProductCardTabs;