import { makeAutoObservable } from 'mobx'

export class Store {
  isLoading = true
  isLogin = false

  constructor() {
    makeAutoObservable(this)
  }
  handleLogin = (value) => {
    this.isLogin = value
  }
}

const store = new Store()
export default store
