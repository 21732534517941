import ImageGallery from 'react-image-gallery'
import './ImageGallery.scss'

const images = [
  {
    original: './images/products/cat2.jpg',
    thumbnail: './images/products/cat2.jpg',
  },
  {
    original: './images/products/cat3.jpg',
    thumbnail: './images/products/cat3.jpg',
  },
  {
    original: './images/products/cat4.jpg',
    thumbnail: './images/products/cat4.jpg',
  },
];

const ImageGalleryLayout = () => {
  return(
      <div style={{
        width: '40%',
      }}>
      <ImageGallery items={images} lazyLoad infinite />
      </div>
  )
}

export default ImageGalleryLayout