import s from './AuthorsCard.module.scss'
import HoverSearchLink from '@UI/HoverSearchLink'

const AuthorsCard = ({ item }) => {
	const { authorPhoto, rating, name, surname, profession } = item
	return (
		<div className={s.authorsCard}>
			<a href=''>
				<div className={s.cardAvatar}>
					<img
						src={`images/authors/${authorPhoto}.jpg`}
						alt={authorPhoto}
					/>
					<div className={s.authorRating}>
						<span className={s.ratingValue}>{rating}</span>
					</div>
					<HoverSearchLink maxRounded={'maxRounded'} />
				</div>
				<div className={s.authorName}>
					<span className={s.name}>{name}</span>
					<span className={s.surname}>{surname}</span>
				</div>
				<span className={s.authorProf}>{profession}</span>
			</a>
		</div>
	)
}

export default AuthorsCard
