import { Route, Routes } from 'react-router-dom'
import s from './styles/App.module.scss'
import HomePage from '@pages/HomePage'
import HeaderFooterLayout from '@layouts/HeaderFooterLayout'
import ProductCardPage from '@pages/ProductCardPage'
import LoginPage from '@pages/LoginPage'

function App() {
  return (
    <div style={{ marginTop: '74px' }} className={s.app}>
      <Routes>
        <Route path="*" element={<HeaderFooterLayout />}>
          <Route index element={<HomePage />} />
          <Route path="itemCard" element={<ProductCardPage />} />
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
