import s from './DropDownNavMenu.module.scss'
import {useContext, useRef, useState} from 'react'
import {dropDownNavMenuData} from './data/dropDownNavMenuData'
import {AnimatePresence, motion} from 'framer-motion'
import {AiOutlineClose} from 'react-icons/ai'
import DropDownNavMenuItem from './DropDownNavMenuItem'
import {HeaderContext} from '@context/headerContext/HeaderContext.js'
import {DropDownNavMenuContext} from './context/DropDownNavMenuContext'
import {Link} from 'react-router-dom'
import HoverSearchLink from '@UI/HoverSearchLink/index.jsx'
import useOutside from '@hooks/useOutside.jsx'

const DropDownNavMenu = ({isOnTop}) => {
    const {ref, isShow, handleVisibility} = useContext(HeaderContext)
    const [activeTab, setActiveTab] = useState('Картины')

    const authors = ['Художники', 'Фотографы','Скульпторы', 'Музыканты' , 'Писатели,сценаристы']
    const isPartOfArray = (activeName, nameArray) => {
        return nameArray.some(name => name.includes(activeName));
    }

    return (
        <DropDownNavMenuContext.Provider value={{setActiveTab, activeTab}}>
            <AnimatePresence initial={false}>
                {isShow ? (
                    <motion.div
                        ref={ref}
                        className={`${s.dropDownNavMenu} ${isOnTop ? '' : s.notOnTop}`}
                        initial={{y: 0, x: '-50%', opacity: 0, zIndex: -10}}
                        animate={{y: 0, x: '-50%', opacity: 1, zIndex: 1}}
                        exit={{y: 0, x: '-50%', opacity: 0, zIndex: -10}}
                        transition={{
                            type: 'spring',
                            stiffness: 650,
                            damping: 32,
                            duration: 0.2,
                        }}
                    >
                        <div className={s.navWrapper}>
                            <nav className={s.wrapper}>
                                <ul>
                                    {dropDownNavMenuData.map((item) => (
                                        item.name !== 'Творцы' ?
                                            <DropDownNavMenuItem item={item} key={item.id}/> :
                                            <Link to={`/authors/${activeTab}`}
                                                  onClick={handleVisibility}>
                                                <DropDownNavMenuItem item={item} key={item.id}/>
                                            </Link>
                                    ))}
                                </ul>
                            </nav>
                            <div className={s.products}>
                                <ul className={s.productsList}>

                                    {!isPartOfArray(activeTab, authors) ? <h3 className={s.activeTabName}>{activeTab}</h3> : null }
                                    {dropDownNavMenuData.map((item) => (
                                        item.name !== 'Творцы' ? <div key={item.id}>
                                            {item.tabs.map((item) => (
                                                <div className={s.productItems} key={item.id}>
                                                    {item.name === activeTab
                                                        ? item.products.map((item) => (
                                                            <li key={item.id}>
                                                                <Link to={item.link}>
                                                                    <div className={s.imgWrapper}>
                                                                        <img
                                                                            src={`./images/menu/${item.img}.jpg`}
                                                                            alt=""
                                                                        />
                                                                        <HoverSearchLink
                                                                            mdRounded={true}
                                                                            small={true}
                                                                        />
                                                                    </div>
                                                                    <span className={s.nameString}>
                                                                        {item.name}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))
                                                        : null}
                                                </div>
                                            ))}
                                        </div> : null
                                    ))}
                                </ul>
                            </div>
                            <button onClick={handleVisibility}>
                                <AiOutlineClose className={s.closeBtn}/>
                            </button>
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </DropDownNavMenuContext.Provider>
    )
}

export default DropDownNavMenu
