export const productCardData = {
    id: 1,
    rating: '10.0',
    productName: 'Кольцо с рубином',
    price: '100000',
    description: '"Иллюзия реальности" - картина, на которой изображена девушка в необычном месте. Над головой у нее повисли неясно знакомые силуэты, создающая вокруг нее особый свет и атмосферу. Девушка неподвижно стоит, будто бы завороженная загадочным странствием.',
    productAuthorImg: 'man1', // img
    authorProfileId: 1,
    productAuthorName: 'Неизвестный',
    productAuthorDescription: 'Привет, меня зовут Леонардо, мне 35 лет и я художник. Родился и вырос в Москве, учился в художественной школе. Сейчас живу и работаю в Париже. Моя основная специализация - портреты, но я также люблю экспериментировать с различными техниками и материалами. Мои работы были представлены на нескольких выставках как в России, так и за рубежом. Я стремлюсь передавать в своих работах эмоции и настроение, создавая уникальные образы.',
    productAuthorJob: 'Ювелир',
    productAuthorRating: '10.0',
    tags: ['Ручная работа', 'Золото', 'Серьги', 'Ювелирные украшения'],
    author_name: 'Автор2',
}
