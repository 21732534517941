import s from './UserMenu.module.scss'
import { BsPerson } from 'react-icons/bs'
import { FiChevronDown } from 'react-icons/fi'
import FullRoundedButton from '@UI/FullRoundedButton'
import {observer} from "mobx-react-lite";
import store from "@store/store.js";

const UserMenu = observer(() => {
	const {handleLogin, isLogin} = store
	return (
		<div className={s.userMenu} onClick={()=>{
			isLogin? handleLogin(false) : handleLogin(true)
		}}>
			<FullRoundedButton userMenu>
				<BsPerson className={s.white} />
			</FullRoundedButton>
			<button className={s.more}>
				<FiChevronDown className={s.orange} />
			</button>
		</div>
	)
})

export default UserMenu
