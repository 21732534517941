export const eventsSectionData = [
  {
    id: 1,
    title: 'САМЫЕ УНИКАЛЬНЫЕ КАРТИНЫ',
    text: `Добро пожаловать в удивительный мир изображений! 
	Насладитесь прекрасными картинами и дайте волю своему воображению! 
	Откройте новые грани искусства и эмоций прямо здесь, прямо сейчас!`,
    img: 'event-1',
    link: '#',
  },
  {
    id: 2,
    title: 'САМЫЕ УДИВИТЕЛЬНЫЕ СКУЛЬПТУРЫ',
    text: `Откройте для себя удивительные формы и эмоции, воплощенные в мраморе, бронзе, дереве и других материалах. 
	Уникальные произведения искусства, созданные мастерами с большой любовью и талантом. 
	Погрузитесь в мир красоты и художественной выразительности, который оставит незабываемое впечатление.`,
    img: 'event-2',
    link: '#',
  },
  {
    id: 3,
    title: 'САМЫЕ КРУТЫЕ МЕЛОДИИ',
    text: `Не упустите возможность узнать самое главное про нашу
  платформу! Мы - ваш путеводитель в мир музыки, где ностальгия 
  сливается с качеством звучания.От классики до современности,дарите себе и своим близким 
  настоящую радость. 
  Музыка живет здесь!`,
    img: 'event-3',
    link: '#',
  },
  {
    id: 4,
    title: 'САМЫЕ ЛУЧШИЕ ЭЛЕМЕНТЫ ДЕКОРА',
    text: `Здесь вы найдете вдохновение и идеи для оформления интерьера вашего дома или рабочего пространства. 
	Богатый выбор элементов декора, которые помогут придать уникальный характер и создать атмосферу, 
	отражающую ваш стиль и вкус.`,
    img: 'event-4',
    link: '#',
  },
  {
    id: 5,
    title: 'САМЫЕ ИЗЫСКАННЫЕ УКРАШЕНИЯ',
    text: `Уникальные и стильные украшения, созданные с любовью и вниманием к деталям. 
	От классических и изысканных до смелых и современных дизайнов, здесь вы найдете то, 
	что подчеркнет вашу индивидуальность и добавит блеска вашему образу.`,
    img: 'event-5',
    link: '#',
  },
]
