import s from "./UserTabInfo.module.scss";
import {Link} from "react-router-dom";
import HoverSearchLink from "@UI/HoverSearchLink/index.jsx";
import {BsFillStarFill} from "react-icons/bs";
import React from "react";
import FullRoundedButton from "@UI/FullRoundedButton/index.jsx";
import {IoPersonCircleOutline} from 'react-icons/io5'
import {HiOutlineChatBubbleBottomCenterText} from 'react-icons/hi2'
import {FiPhoneCall} from 'react-icons/fi'
const UserTabInfo = ({authorImg, authorName, authorJob, authorRating, extendedButtons}) => {
    return (
        <div className={s.userTabInfo}>
            <div className={s.userInfo}>
                <div className={s.userInfoImg}>
                    <Link className={s.toProfileImg} to='/profile'> <HoverSearchLink maxRounded small/> <img
                        src={`/images/authors/${authorImg}.jpg`}/></Link>


                    {extendedButtons ?
                        <div className={s.extendedButtons}>
                            <FullRoundedButton><Link to='/123'><IoPersonCircleOutline/></Link></FullRoundedButton>
                            <FullRoundedButton><Link to='/123'><HiOutlineChatBubbleBottomCenterText/></Link></FullRoundedButton>
                            <FullRoundedButton><Link to='/13'><FiPhoneCall/></Link></FullRoundedButton>
                        </div> : <
                            Link className={s.toProfile} to='/profile'>В профиль</Link>
                    }
                </div>
                <div className={s.userInfoInfo}>
                    <span className={s.userInfoInfoName}>{authorName}</span>
                    <span className={s.userInfoInfoJob}>{authorJob}</span>
                    <span className={s.userInfoInfoRating}> <BsFillStarFill
                        className={s.ratingStar}/>{authorRating.toFixed(1)}</span>
                </div>
            </div>
        </div>
    );
};

export default UserTabInfo;