import s from './EventsSectionItem.module.scss'
import {
	MdKeyboardArrowRight,
	MdKeyboardDoubleArrowLeft,
	MdKeyboardDoubleArrowRight,
} from 'react-icons/md'

const EventsSectionItem = ({
	id,
	title,
	text,
	img,
	link,
	currentIndex,
	prevSlide,
	nextSlide,
	setIsTimerActive,
}) => {
	return (
		<div
			className={`${s.eventsSectionItem} ${
				currentIndex === id - 1 ? s.visible : s.hidden
			}`}
			onMouseOver={() => setIsTimerActive(false)}
			onMouseLeave={() => setIsTimerActive(true)}
		>
			<div className={s.eventsSectionItemContent}>
				<h2>{title}</h2>
				<p>{text}</p>
				<a href={link}>
					<span>Посмотреть</span> <MdKeyboardArrowRight />
				</a>
			</div>
			<div className={s.eventsSectionItemImages}>
				<img src={`images/eventsSection/${img}.jpg`} alt='event-1' />
				<div className={s.eventsSectionItemImagesControls}>
					<button onClick={prevSlide}>
						<MdKeyboardDoubleArrowLeft />
					</button>
					<button onClick={nextSlide}>
						<MdKeyboardDoubleArrowRight />
					</button>
				</div>
			</div>
		</div>
	)
}

export default EventsSectionItem
