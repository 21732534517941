import s from './ProductsList.module.scss'
import ProductCard from '@components/ProductCard/index.jsx'
const ProductsList = ({dataList}) =>{
    return (
        <div className={s.productsList}>
            {dataList.map((item) => (
                <ProductCard item={item} key={item.id}/>
            ))}
        </div>
    )
}

export default ProductsList
