import {useState, useRef, useEffect} from "react";

export default function useOutside
    (initialIsVisible) {
    const [isShow, setIsShow] = useState(initialIsVisible)
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
                setTimeout(() => {setIsShow(false)})
        }
    }
    useEffect(() => {
            document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })
    return {ref, isShow, setIsShow}
}
