import BigProductCard from "@components/BigProductCard/BigProductCard.jsx";
import s from './ProductCardPage.module.scss'
import {productCardData} from './productCardData/productCardData.js'
import BackButton from "@UI/BackButton/index.jsx";
import ProductCardTabs from "@components/ProductCardTabs/index.jsx";
import {ProductCardContext} from '@context/ProductCardContext/ProductCardContext.js'
import AnotherProductsAuthorSection from "@components/AnotherProductsOfThisAuthor/AnotherProductsAuthorSection";
import CommonProductsSection from "@components/CommonProductsSection/CommonProductsSection";
import {useEffect, useState} from "react";
import {scrollToTop} from "@hooks/scrollToTop.js";

const ProductCardPage = ({id}) => {


    // const [loading, setLoading] = useState(false)
    // const [user, setUser] = useState()

    // const init = async () => {
    //     setLoading(true)
    //     // 'const card = await getCard(id)'
    //     // 'const  user = await getUser(user_id)'
    //     // 'setUser(user)'
    //     setLoading(false)
    //
    // }
    
    useEffect(() => {
        scrollToTop()
    }, [])

    // useEffect(() => {
    //     init()
    // }, [])
    //
    // return <div className={'preloader'}>Loading</div>

    return (
        <div>
            <BackButton/>
            <div className={s.croshka}>Картина > Абстракция > Картина “Иллюзия реальности”

            </div>
            <ProductCardContext.Provider value={productCardData}>
                <BigProductCard/>
                <ProductCardTabs/>
                <AnotherProductsAuthorSection/>
                <CommonProductsSection/>
            </ProductCardContext.Provider>
        </div>
    )
}

export default ProductCardPage