import s from './ProductCard.module.scss'
import {BsSuitHeartFill} from 'react-icons/bs'
import AddToCartBtn from '@UI/AddToCartBtn'
import FullRoundedButton from '@UI/FullRoundedButton'
import HoverSearchLink from '@UI/HoverSearchLink'
import {BsFillStarFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'

const ProductCard = ({item}) => {
    const {
        productPhoto,
        rating,
        productName,
        price,
        productAuthorImg,
        productAuthorName,
        productAuthorJob,
        productAuthorRating,
        tags,
    } = item

    return (
        <div className={s.productCard}>
            <Link to='/itemCard' className={s.productCardImg}>
                <img
                    src={`images/products/${productPhoto}.jpg`}
                    alt={productPhoto}
                />
                <HoverSearchLink mdRounded={'mdRounded'}/>
            </Link>
            <div className={s.productCardContent}>
                <span className={s.productCardContentRating}>{rating}</span>
                <div className={s.productCardContentInfo}>
					<span className={s.productCardContentInfoName}>
						{productName}
					</span>
                    <span className={s.productCardContentInfoPrice}>{price}₽</span>
                </div>
                <div href="" className={s.authorWrapper}>
                    <div className={s.leftWrapper}>
                        <a className={s.productCardContentAuthor} href='#'>
                            <div className={s.productCardContentAuthorPhoto}>
                                <img
                                    src={`images/authors/${productAuthorImg}.jpg`}
                                    alt={productAuthorImg}
                                />
                                <HoverSearchLink small={'small'}/>
                            </div>
                            <div className={s.productCardContentAuthorName}>
                                <span>{productAuthorName}</span>
                                <span>{productAuthorJob}</span>
                            </div>
                        </a>
                    </div>
                    <div className={s.ratingWrapper}>
                        <BsFillStarFill className={s.ratingStar}/>
                        {productAuthorRating}
                    </div>
                </div>
                <ul className={s.productCardContentTags}>
                    {tags.map((item, i) => (
                        <li key={i}>
                            <a href='#'>#{item}</a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={s.productCardBtns}>
                <AddToCartBtn product={'product'}/>
                <FullRoundedButton product={'product'}>
                    <BsSuitHeartFill/>
                </FullRoundedButton>
            </div>
        </div>
    )
}

export default ProductCard
