import { commonProductsSectionData } from './data/commonProductsSectionData.js'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'

const CommonProductsSection = () => {
	return (
		<ProductsSectionLayout
			title={'Похожие товары'}
			data={commonProductsSectionData}
		></ProductsSectionLayout>
	)
}
 
export default CommonProductsSection