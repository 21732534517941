import { offeredProductsSectionData } from './data/offeredProductsSectionData.js'
import ProductsSectionLayout from '../../layouts/ProductsSectionLayout'

const OfferedProductsSection = () => {
	return (
		<ProductsSectionLayout
			title={'Товары'}
			data={offeredProductsSectionData}
		></ProductsSectionLayout>
	)
}

export default OfferedProductsSection
