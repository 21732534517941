import s from './SearchBar.module.scss'
import { useState } from 'react'
import { GoSearch } from 'react-icons/go'
import { IoClose } from 'react-icons/io5'
import { AnimatePresence, motion } from 'framer-motion'

const SearchBar = ({ isSearchBarActive, isOnTop }) => {
	const [value, setValue] = useState('')

	const handleChange = (e) => {
		setValue(e.target.value)
	}
	const clearValue = () => {
		setValue('')
	}

	return (
		<AnimatePresence initial={false}>
			{isSearchBarActive ? (
				<motion.div
					className={`${s.searchBar} ${isOnTop ? '' : s.notOnTop}`}
					initial={{ y: -45, opacity: 0, zIndex: -10 }}
					animate={{ y: -1, opacity: 1, zIndex: 1 }}
					exit={{ y: -45, opacity: 0, zIndex: -10 }}
					transition={{
						type: 'spring',
						stiffness: 650,
						damping: 32,
						duration: 0.2,
					}}
				>
					<input
						placeholder='Поиск...'
						type='search'
						value={value}
						onChange={handleChange}
					/>
					<div className={s.searchBarBtns}>
						<button onClick={clearValue}>
							<IoClose />
						</button>
						<button>
							<GoSearch />
						</button>
					</div>
				</motion.div>
			) : null}
		</AnimatePresence>
	)
}

export default SearchBar
