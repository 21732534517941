import s from '../Logo.module.scss'
import { Link } from 'react-router-dom'

const LogoStl = () => {
	return (
		<Link className={s.logo} to='/'>
			<img src='stllogo.svg' alt='logo' />
		</Link>
	)
}

export default LogoStl