import s from '../Logo.module.scss'
import { Link } from 'react-router-dom'

const Logo = () => {
	return (
		<Link className={s.logo} to='/'>
			<img src='logo.svg' alt='logo' />
		</Link>
	)
}

export default Logo
