export const languageListData = [
    {
        id: 1,
        name: 'Русский',
        code: 'Ru'
    },
    {
        id: 2,
        name: 'English',
        code: 'En'
    },
    {
        id: 3,
        name: 'Türkçe',
        code: 'Tk'
    }
]