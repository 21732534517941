import s from '../Logo.module.scss'
import { Link } from 'react-router-dom'

const LogoGraplhic = () => {
	return (
		<Link className={s.logo} to='/'>
			<img src='logo-2.svg' alt='logo' />
		</Link>
	)
}

export default LogoGraplhic
