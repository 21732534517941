import React from 'react'
import s from './ProductsSectionLayout.module.scss'
import ProductsList from '../ProductsList/index.jsx'
import ShowMoreBtn from "@UI/ShowMoreBtn/index.jsx";

const ProductsSectionLayout = ({ title, data }) => {
	return (
		<section className={s.productsSectionLayout}>
			<h2>{title} <ShowMoreBtn /></h2>
			<div className={s.wrapper}>
				<ProductsList dataList={data} />
			</div>
		</section>
	)
}

export default ProductsSectionLayout
