export const fakeCommentData2 = [
    {
        id: 1,
        commentRating: 10.450,
        commentText: 'Норм картина',
        commentAttachments: [],
        commentDate: '10.01.23',
        author_name: 'John',
    },
    {
        id: 2,
        commentRating: 10.65,
        commentText: 'В туалет повесить можно',
        commentAttachments: [
            {
                id: 1,
                type: 'img',
                src: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg'
            }
        ],
        commentDate: '16.10.23',
        author_name: 'Alex',

    },
    {
        id: 3,
        commentRating: 6.45,
        commentText: 'Люблю котиков конечно, но эти какие-то странные',
        commentAttachments: [],
        commentDate: '16.10.23',
        author_name: 'Frank',

    }
]