export const commonProductsSectionData = [
	{
		id: 1,
		productPhoto: 'artspace',
		rating: '10.0',
		productName: 'Картина "Космос"',
		price: '80 000',
		productAuthorImg: 'author5',
		productAuthorName: 'Неизвестный',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Космос', 'Неизвестный', 'Арт', 'Ручная работа'],
	},
	{   id: 2,
		productPhoto: 'artplanet',
		rating: '10.0',
		productName: 'Картина "Планеты"',
		price: '350 600',
		productAuthorImg: 'author6',
		productAuthorName: 'Анна Бель',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Планеты', 'Черно-белая картина', 'Космос'],
	},
	{
		id: 3,
		productPhoto: 'artcube',
		rating: '10.0',
		productName: 'Картина "Невесомый куб"',
		price: '1 000 000',
		productAuthorImg: 'author7',
		productAuthorName: 'Роза',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Искусство', 'Куб', 'Невесомый куб', 'Арт'],
	},
	{
		id: 4,
		productPhoto: 'artface',
		rating: '10.0',
		productName: 'Картина "Я"',
		price: '356 895',
		productAuthorImg: 'author8',
		productAuthorName: 'Анастасия',
		productAuthorJob: 'Художник',
		productAuthorRating: '10.0',
		tags: ['Картины', 'Внутреннее Я', 'Арт', 'Искусство', 'Креатив'],
	},
]