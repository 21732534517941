import s from './Footer.module.scss'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { BiCaretDown } from 'react-icons/bi'
import { FaTelegramPlane } from 'react-icons/fa'
import { SlSocialVkontakte } from 'react-icons/sl'
import LogoGraplhic from '@UI/Logos/LogoGraplhic'
import LogoRanh from '@UI/Logos/LogoRanh'
import LogoCluster from '@UI/Logos/LogoCluster'
import LogoStl from '@UI/Logos/LogoStl'
import LogoPractice from '@UI/Logos/LogoPractice'

const variants = {
  open: {
    y: 0,
  },
  closed: {
    y: '100%',
  },
}

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <motion.footer
      className={s.footer}
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
      transition={{
        type: 'spring',
        stiffness: 650,
        damping: 48,
      }}
    >
      <div className={s.wrapper}>
        <div className={s.leftWrapper}>
          <LogoGraplhic />
          <div className={s.nav}>
            <ul>
              <li>
                <a href="#">О нас</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
              <li>
                <a href="#">Помощь</a>
              </li>
              <li>
                <div className={s.icons}>
                  <a href="">
                    <SlSocialVkontakte />
                  </a>
                  <a href="">
                    <FaTelegramPlane />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={s.rightWrapper}>
          <div className={s.documents}>
            <h3>Документы</h3>
            <ul>
              <li>
                <a href="">Пользовательское соглашение</a>
              </li>
              <li>
                <a href="">Политика конфиденциальности</a>
              </li>
              <li>
                <a href="">Cookie</a>
              </li>
            </ul>
            <p className={s.stl}> © 2010-2023 «Смарт Техно ЛАБ»</p>
            <p className={s.rights}> Все права защищены.</p>
          </div>
          <div className={s.logotypes}>
            <LogoStl />
            <LogoPractice />
            <LogoCluster />
            <LogoRanh />
          </div>
        </div>
        <button
          className={`${s.toggleVisibility} ${isOpen ? s.open : ''}`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <BiCaretDown />
        </button>
      </div>
    </motion.footer>
  )
}

export default Footer
