import { subscribedOffersSectionData } from '@components/SubscribedOffersSection/data/subscribedOffersSectionData.js'
import OffersSection from '@components/OffersSection/index.jsx'
import { offersSectionData } from '@components/OffersSection/data/offersSectionData.js'

export const IS_LOGIN_DATA = {
  subscribedOffersSection: {
    title: 'Товары и услуги авторов, на которых вы подписаны',
    data: subscribedOffersSectionData,
  },
  offersSection: {
    title: 'Предложения для вас',
    data: offersSectionData,
  },
}

export const IS_NOT_LOGIN_DATA = {
  subscribedOffersSection: {
    title: 'Товары',
    data: subscribedOffersSectionData,
  },
  OffersSection: {
    title: 'Услуги',
    data: offersSectionData,
  },
}
