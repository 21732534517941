export const serviceSliderItem =[
    {
        id: 1,
        title: 'УСЛУГИ С ВЫСОКИМ РЕЙТИНГОМ',
        img: 'photo4nik',
    },
    {
        id: 2,
        title: 'УСЛУГИ, НАБИРАЮЩИЕ ПОПУЛЯРНОСТЬ',
        img: 'complukter',
    },
    {
        id: 3,
        title: 'УСЛУГИ НОВЫХ АВТОРОВ',
        img: 'kist',
    },
    {
        id: 4,
        title: 'УСЛУГИ АВТОРОВ, БЫСТРО НАБИРАЮЩИХ ПОПУЛЯРНОСТЬ',
        img: 'dino',
    },
    {
        id: 5,
        title: 'БЫСТРООКАЗЫВАЕМЫЕ УСЛУГИ',
        img: 'trid',
    }
]