export const fakeAuthorData = [
    {
        id: 1,
        authorImg: 'man1',
        authorName: 'Автор1',
        authorJob: 'Кто-то',
        authorRating: 10.0,
        authorDescription: 'Я хороший человек',
    },
    {
        id: 2,
        authorImg: 'man2',
        authorName: 'Автор2',
        authorJob: 'Ассинезатор',
        authorRating: 7.0,
        authorDescription: 'Я замечательный человек',
    },
    {
        id: 3,
        authorImg: 'timur2',
        authorName: 'Anonimus374',
        authorJob: 'Перфекционист',
        authorRating: 20.0 ,
        authorDescription: 'Я замечательный человек',
    },
    {
        id: 4,
        authorImg: 'timur2',
        authorName: 'Frank',
        authorJob: 'Мечтатель',
        authorRating: 20.0 ,
        authorDescription: 'Я замечательный человек',
    },
    {
        id: 5,
        authorImg: 'man2',
        authorName: 'John',
        authorJob: 'Коллекционер',
        authorRating: 20.0 ,
        authorDescription: 'Я замечательный человек',
    },
    {
        id: 6,
        authorImg: 'woman2',
        authorName: 'Alex',
        authorJob: 'Металлическая белка',
        authorRating: 20.0 ,
        authorDescription: 'Я замечательный человек',
    }
]