
export const offeredProductsSectionData = [
	{
		id: 1,
		productPhoto: 'ring2',
		rating: '10.0',
		productName: 'Фотосессия',
		price: '101500',
		productAuthorImg: 'woman2',
		productAuthorName: 'Анна Далакян',
		productAuthorJob: 'Фотограф',
		productAuthorRating: '10.0',
		tags: ['Фотосессия', '#Фотограф'],
	},
	{id: 2,
		productPhoto: 'catgirl',
		rating: '10.0',
		productName: 'Картина "Девушка и Кот"',
		price: '5500',
		productAuthorImg: 'man2',
		productAuthorName: 'Артур Дрозд',
		productAuthorJob: 'Писатель',
		productAuthorRating: '10.0',
		tags: ['Черно-белая фотосессия', '#Фотограф'],
	},
	{
		id: 3,
		productPhoto: '3dmodel',
		rating: '10.0',
		productName: 'Игровой персонаж',
		price: '10000',
		productAuthorImg: 'man1',
		productAuthorName: 'Саня',
		productAuthorJob: 'Ювелир',
		productAuthorRating: '10.0',
		tags: ['Ручная работа', 'Золото', 'Серьги', 'Ювелирные украшения'],
	},
	{
		id: 4,
		productPhoto: '3dmodellion',
		rating: '10.0',
		productName: '3D Модель "Лев"',
		price: '350000',
		productAuthorImg: 'timur',
		productAuthorName: 'Тимур Котик',
		productAuthorJob: 'Котик',
		productAuthorRating: '10.0',
		tags: ['Котик', 'Котик', 'Котик', 'Котик'],
	},
]
