import s from "./BackButton.module.scss";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate()
    return (
        <button className={s.backBtn} onClick={() => {
            navigate(-1)
        }}><BiArrowBack/></button>
    );
};

export default BackButton;