export const offersSectionData = [
	{
		id: 1,
		productPhoto: 'ring',
		rating: '10.0',
		productName: 'Кольцо с рубином',
		price: '100 000',
		productAuthorImg: 'man1',
		productAuthorName: 'Неизвестный',
		productAuthorJob: 'Ювелир',
		productAuthorRating: '10.0',
		tags: ['Ручная работа', 'Золото', 'Серьги', 'Ювелирные украшения'],
	},
	{
		id: 2,
		productPhoto: 'necklace',
		rating: '10.0',
		productName: 'Кулон "Королева" ',
		price: '2500',
		productAuthorImg: 'woman2',
		productAuthorName: 'Анна Далакян',
		productAuthorJob: 'Фотограф',
		productAuthorRating: '10.0',
		tags: ['Черно-белая фотосессия', 'Фотограф'],
	},
	{
		id: 3,
		productPhoto: 'purse',
		rating: '10.0',
		productName: 'Сумка "Инь-Янь"',
		price: '5500',
		productAuthorImg: 'man2',
		productAuthorName: 'Артур Дрозд',
		productAuthorJob: 'Писатель',
		productAuthorRating: '10.0',
		tags: ['Сумка', 'Ручная работа', 'Инь-Янь'],
	},
	{
		id: 4,
		productPhoto: 'cat3',
		rating: '10.0',
		productName: 'Каменная скульптура "Кот"',
		price: '3500',
		productAuthorImg: 'woman1',
		productAuthorName: 'Анастасия Кравец',
		productAuthorJob: 'Скульптор',
		productAuthorRating: '10.0',
		tags: ['Ручная работа', 'Скульптуры', 'Эйнштейн', 'Креатив'],
	},
]
