import s from './EventsSection.module.scss'
import { useEffect, useState } from 'react'
import EventsSectionItem from './EventsSectionItem'
import { eventsSectionData } from './data/eventsSectionData'

const EventsSection = () => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [isTimerActive, setIsTimerActive] = useState(true)

	useEffect(() => {
		const timerId = setTimeout(() => {
			isTimerActive && nextSlide()
		}, 7000)

		return () => clearTimeout(timerId)
	}, [currentIndex, isTimerActive])

	const prevSlide = () => {
		setCurrentIndex(
			currentIndex <= 0
				? currentIndex + (eventsSectionData.length - 1)
				: currentIndex - 1
		)
	}
	const nextSlide = () => {
		setCurrentIndex(
			currentIndex === eventsSectionData.length - 1
				? currentIndex - (eventsSectionData.length - 1)
				: currentIndex + 1
		)
	}
	const setSlide = (index) => {
		setCurrentIndex(index)
	}

	return (
		<section className={s.eventsSection}>
			<div className={s.wrapper}>
				<div className={s.wrapperItems}>
					{eventsSectionData.map((item) => (
						<EventsSectionItem
							{...item}
							currentIndex={currentIndex}
							prevSlide={prevSlide}
							nextSlide={nextSlide}
							setIsTimerActive={setIsTimerActive}
							key={item.id}
						/>
					))}
				</div>
			</div>
				<ul className={s.eventsSectionControls}>
					{eventsSectionData.map((item, i) => (
						<li key={item.id}>
							<button
								className={`${
									currentIndex === item.id - 1 ? s.active : ''
								}`}
								onClick={() => setSlide(i)}
							></button>
						</li>
					))}
				</ul>
		</section>
	)
}

export default EventsSection
